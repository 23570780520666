<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-input v-model="searchKey" placeholder="活动名称"></el-input>
			</div>
			<!--优惠券类型  -->
			<div class="filter-item">
				<label class="label">活动类型:</label>
				<el-select v-model="type" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option
						v-for="item in typeOptions"
						:key="item.value"
						:label="item.lable"
						:value="item.value"
					></el-option>
				</el-select>
			</div>
			<!-- 优惠券状态 -->
			<div class="filter-item">
				<label class="label">活动状态:</label>
				<el-select v-model="status" placeholder="请选择">
					<el-option
						v-for="item in statusOptions"
						:key="item.value"
						:label="item.lable"
						:value="item.value"
					></el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addPriceBreak'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleAddCoupon">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading="loading">
				<el-table-column prop="activityName" label="活动名称"></el-table-column>
				<el-table-column prop="ActivityTime" width="150px" label="活动时间"></el-table-column>
				<el-table-column prop="ConditionTypeName" label="类型"></el-table-column>
				<el-table-column prop="StateName" label="状态"></el-table-column>
				<el-table-column label="活动内容" width="130px">
					<template slot-scope="scope">
						<el-tooltip
							class="item-tooltip"
							effect="light"
							popper-class="pop-item-tooltip"
							:content="scope.row.ActivityContent"
							placement="top"
						>
							<div
								style="width:100px;word-break:normal;overflow:hidden;text-overflow:ellipsis "
							>{{scope.row.ActivityContent}}</div>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column label="支付订单">
					<template slot-scope="scope">
						<span v-if="scope.row.orderNumber==0">0</span>
						<el-button v-else type="text" @click="handleRouteJump(scope.row)">{{scope.row.orderNumber}}</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="peoPleNumber" label="支付人数"></el-table-column>
				<el-table-column label="支付金额">
					<template slot-scope="scope">
						<span>{{+scope.row.amount}}</span>
					</template>
				</el-table-column>
				<el-table-column label="客单价">
					<template slot-scope="scope">
						<span>{{+scope.row.peolpeAmount}}</span>
					</template>
				</el-table-column>
				<el-table-column label="笔单价">
					<template slot-scope="scope">
						<span>{{+scope.row.orderAmount}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" width="150px" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="220px">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<!-- <button-permissions :datas="'priceBreakTg'">
								<div
									class="table-button"
									v-if="scope.row.actType!=2"
									@click="handleTablePromote(scope.row.Id)"
								>推广</div>
							</button-permissions> -->
							
							<div class="table-button" @click="handleTableEdit(scope.row)">查看</div>
							<button-permissions :datas="'copyPriceBreak'">
								<div class="table-button" @click="handleTableCopy(scope.row)">复制</div>
							</button-permissions>
							<button-permissions v-if="scope.row.actType==2" :datas="'deletePriceBreak'">
								<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.id)">删除</div>
							</button-permissions>
							
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="page.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="page.current"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total"
			></el-pagination>
		</div>
		<!-- add-dialog -->
		<el-dialog title="添加满减/送活动" :visible.sync="editDialog" width="40%" class="dialog">
			<div class="content">
				<div class="left">我要添加</div>
				<div class="right">
					<el-radio v-model="editRadio" :label="0">满N元减/送</el-radio>
					<div class="text">例如：满100元减5元；每满100元减5元</div>
					<el-radio v-model="editRadio" :label="1">满N件减/送</el-radio>
					<div class="text">例如：满2件减5元；每2件减5元</div>
				</div>
			</div>
			<div class="dialog-footer">
				<el-button class="button" @click="addCouponSure" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<!-- 推广的弹框 -->
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel"></batch-cods>
	</div>
</template>

<script>
import apiList from '@/api/other'
import buttonPermissions from '@/components/buttonPermissions';
import batchCods from "@/components/bathCodes"
export default {
	components: {
		buttonPermissions,
		batchCods
	},
	data () {
		return {
			weiCodeData: {},
			weiCodeShow: false,
			bathSortDatas: [],
			loading: false,
			searchKey: '',
			type: null,
			typeOptions: [{
				lable: '满N元减/送',
				value: 0
			},
			{
				lable: '满N件减/送',
				value: 1
			}
			],
			status: null,
			statusOptions: [{
				lable: '全部',
				value: null
			},
			// {
			// 	lable: '未开始',
			// 	value: 0
			// },
			{
				lable: '进行中',
				value: 1
			},
			{
				lable: '已结束',
				value: 2
			}
			],
			tableData: [],
			page: {
				total: 0,
				current: 1,
				size: 20
			},
			editDialog: false,
			editRadio: 0,
		}
	},
	created () {
		this.getList()
	},
	methods: {

		// 获取列表数据
		async getList () {
			this.loading = true;
			try {
				let data = {
					keyWords: this.searchKey,
					type: this.type,//类型 0:满N元送，1：满N件送
					actType: this.status,//活动状态 1：活动中，2 已结束
					Skip: (this.page.current - 1) * this.page.size,
					Take: this.page.size,
				}
				let result = await apiList.activityMarkdownGiftList(data);

				this.page.total = result.data.total;
				this.tableData = result.data.records || [];
				this.tableData.forEach(t => {
					t.orderAmount = t.orderAmount.toFixed(2)
					t.amount = t.amount.toFixed(2)
					t.peolpeAmount = t.peolpeAmount.toFixed(2)
					t.ActivityTime = t.begTime + " ~ " + t.endTime;
					t.StateName = t.actType == 1 ? '进行中' : '已结束';
					t.ConditionTypeName = t.type == 0 ? '满N元送' : '满N件送';
				})
			} catch (error) {
				console.log(error);
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
		handleFilter () {
			this.getList()
		},
		handleAddCoupon () {
			this.editDialog = true
		},
		//支付订单跳转
		handleRouteJump (val) {
			this.$router.push({
				path: '/order/orderMange',
				query: {
					ActivityId: val.Id,
					ActivityType: 2,
					starTime: val.StartTime,
					endTime: val.EndTime,
				}
			})
		},
		// table新增
		addCouponSure () {
			this.$router.push({
				path: '/market/discountActivity/editOverMoney',
				query: {
					activeType: this.editRadio
				}
			})
		},
		//复制
		handleTableCopy (val) {
			this.$router.push({
				path: '/market/discountActivity/editOverMoney',
				query: {
					copyId: val.id,
					activeType: val.type || 0
				}
			})
		},
		// table编辑，查看
		handleTableEdit (val) {
			// status：:1：未开始，所有的都可以编辑，2：进行中，不可编辑开始时间，3：已结束，查看，所有的都不能编辑
			this.$router.push({
				path: '/market/discountActivity/editOverMoney',
				query: {
					id: val.id,
					activeType: val.type || 0,
					status: val.actType+1
				}
			})
		},
		// table使终止
		handleTableFail (id) {
			this.$confirm('活动终止后，你将不可再对这场活动进行编辑，是否确认使终止？ ', '提示', {
				confirmButtonText: '确认终止',
				cancelButtonText: '关闭',
				type: 'warning'
			}).then(async () => {

				let result = await apiList.activityMarkdownGiftIsOpen({
					id: id
				})
				if (result.IsSuccess) {
					this.getList()
					this.$message({
						showClose: true,
						type: 'success',
						message: '活动已终止!'
					});
				}
			}).catch(() => {
				this.$message({
					showClose: true,
					type: 'info',
					message: '已取消终止'
				});
			}).finally(() => { })
		},
		// table数据
		handleTableData () {
			this.showDataDialog = true
		},
		// table删除
		handleTableDelete (id) {
			this.$confirm('是否确认删除本场活动？删除后不可恢复', '提示', {
				confirmButtonText: '删除对应活动',
				cancelButtonText: '关闭',
				type: 'warning'
			}).then(async () => {
				let data = {
					id: id
				}
				let result = await apiList.activityMarkdownGiftDel(data)
				if (result.success) {
					this.getList()
					this.$message({
						showClose: true,
						type: 'success',
						message: '删除成功!'
					});
				}else{
					this.$message({
						showClose: true,
						type: 'error',
						message: '操作失败,请重试'
					});
				}
			}).catch(() => {
				this.$message({
					showClose: true,
					type: 'info',
					message: '已取消删除'
				});
			}).finally(() => { })
		},
		// 推广-满减送
		handleTablePromote (id) {
			this.weiCodeShow = true;
			this.weiCodeData = {
				Page: 'pages/f/f',
				Scene: `id=${id}`
			}
		},
		weiCodeCancel () {
			this.weiCodeShow = false;
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.page.size = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.page.current = val;
			this.getList();
		},
		handleClose () { },
	}
}
</script>

<style lang="less" scoped>
.app-container {
	/deep/.el-input {
		width: 220px;
	}

	background-color: #fff;
}

.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}

.dialog {
	width: 100%;

	/deep/.el-dialog__header {
		border-bottom: 1px solid #ddd;
	}

	.content {
		padding: 0 30px;
		display: flex;
		flex-direction: row;

		.right {
			margin-left: 20px;
		}

		.text {
			margin-left: 20px;
			font-size: 14px;
			color: #999;
			line-height: 40px;
		}
	}

	.button {
		margin: 0 auto;
		width: 200px;
	}
}

.data-dialog {
	.value {
		line-height: 50px;
		font-size: 24px;
		color: #000;
	}

	.item {
		color: #666;
		margin-top: 10px;
	}
}

.el-pagination {
	/deep/.el-input {
		width: 100px;
	}
}

.dialog-footer {
	text-align: center;
	margin-top: 20px;
}

.item-tooltip {
	width: 255px;
}

// .draw_share_atooltip{
//     background: #515b62 !important;
// }
.el-tooltip__popper[x-placement^="right"] .popper__arrow {
	border-right-color: #1ab394;
}

.el-tooltip__popper[x-placement^="right"] .popper__arrow:after {
	border-right-color: #1ab394;
}

.pop-item-tooltip {
	background: #1ab394 !important;
}

// .pop-item-tooltip{
// 	background: #515b62 !important;
// }
// .pop-item-tooltip.el-tooltip__popper[x-placement^="top"] .popper__arrow {
// 	border-top-color: #515b62;
// }
// .pop-item-tooltip.el-tooltip__popper[x-placement^="top"] .popper__arrow:after {
// 	border-top-color: #515b62;
// }
</style>
